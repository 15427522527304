import * as React from "react"
import { Fragment } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"

const PortfolioHero = ({title, description, src, alt}) => {
    return (
        <Fragment>
            <section className="bmo-hero hero-full-size not-root-path">
                <div className="bmo-container">
                    <div className="bmo-breadcrumb">
                        <nav className="bread-crumb" aria-label="Breadcrumb">
                            <ul>
                                <li><Link to="/" className="bread-crumb-link">Work<span className="icon small baby-chevy-right"></span></Link></li>
                                <li><Link to="#" className="bread-crumb-link active">{title}</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="hero-banner-container">
                        <div className="hero-banner-container--content">
                            <h1>{title}</h1>
                            <h2>{description}</h2>
                        </div>
                        <GatsbyImage image={src} className="hero-banner-container--image" alt={alt} />
                    </div>
                    <div className="scroll-indicator">
                        <a href="#main-content">
                        <StaticImage
                            src="../images/caret-down.svg"
                            alt="caret-pointing-downwards"
                            objectFit="contain"
                            placeholder="blurred"
                            height={42}
                            width={42}
                            className="scrolldown-p1"
                        />
                        <span className="sr-only">Click here to scroll down to the first section</span>
                        </a>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default PortfolioHero
