import * as React from "react"
import { Fragment } from "react"
import { StaticImage } from "gatsby-plugin-image"

const LinkButton = ({ text, link, buttonclass, iconclass, target }) => {

    return (
        <Fragment>
            <a href={link} target={target} rel="noopener noreferrer" className={buttonclass}>
                <div className="inside-link-wrap">
                {text}<StaticImage src="../images/24-arrow-right.svg" alt="arrow pointing to right" imgClassName={iconclass} placeholder="blurred" className="button-icon" />
                </div>
            </a>
        </Fragment>
    )
}

export default LinkButton