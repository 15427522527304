import * as React from "react"
import { Link } from "gatsby"
import { Fragment } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const Card = ({cardheader, imagedata, cardbody, cardtype, cardlink, imagealt}) => {

    return (
        <Fragment>
            <li className={`card-component ${cardtype}`}>
                <Link to={cardlink} >
                    <GatsbyImage image={imagedata} alt={imagealt} className="card-component-image" />
                    <div className="card-component-content">
                        <p className="badge">Featured</p>
                        <h3>{cardheader}</h3>
                        <p>{cardbody}</p>
                        <div className="card-component-readmore">
                            <p className="read-more-text">View project</p>
                            <StaticImage src="../images/24-arrow-right.svg" alt="arrow pointing to right" imgClassName="filter-bmobluea" placeholder="blurred" />
                        </div>
                    </div>
                </Link>
            </li>
        </Fragment>
    )
}

export default Card