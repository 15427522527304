import * as React from "react"
import { Fragment } from "react"
import { Link } from "gatsby"
import bmoLogo from '../images/bmo-roundel-logo-dark-bg.svg'


const HeaderNav = ({ hideLinks }) => {
    return (
        <Fragment>
        <div className="bmo-header full-blue underline">
        <div className="bmo-container">
          <div className="bmo-logo">
            <Link to="/">
              <img src={bmoLogo} alt="BMO Bank of Montreal Logo - Home Link" />
            </Link>
            </div>
            {hideLinks ? null
              : <div className="header-content">
                  <nav className="primary-nav" aria-label="Main">
                    <ul>
                      <li>
                        <Link to="/" className="primary-link">Work</Link>
                      </li>
                      <li>
                        <a href="https://intranet.bmogc.net/corporate/portal/marketing/design/Pages/ReachOut.aspx" target="_blank" rel="noopener noreferrer" className="primary-link">Contact</a>
                      </li>
                    </ul>
                  </nav>
              </div>
            }
        </div>
      </div>
      </Fragment>
    )
}

export default HeaderNav