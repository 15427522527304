import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import HeaderNav from "../components/header-nav"
import bmoLogo from '../images/bmo-roundel-logo-dark-bg.svg'
import LinkButton from "../components/linkbutton"

const NotFoundPage = ({ data, location }) => {

  return (
    <div className="container-404">
      <a href="#main-content" className="header-skip">Skip to main content</a>
      <Seo title={ location.state?.hideLinks ? "404: Unauthorized Access" : "404: Not Found" } />
      <header>
        <HeaderNav hideLinks={location.state?.hideLinks} />
      </header>
      <main id="main-content">
        <section className="bmo-container">
          <div className="main-container-404">
            <h1>404: { location.state?.hideLinks ? "Unauthorized Access" : "Not Found" }</h1>
            <p>Sorry! { location.state?.hideLinks ? "You are not authorized to view this page." : "This page does not exist." }</p>
          </div>
        </section>
      </main>
      <footer>
        <div className="bmo-container">
          <div className={ location.state?.hideLinks ? "footer-container decrease-padding" : "footer-container" }>
            {location.state?.hideLinks
              ? null
              : <div className="footer-container--about-us">
                <h2>We are BMO Design.</h2>
                <p>An award-winning in-house design agency helping our partners achieve marketing goals through the power of great design.</p>
                <LinkButton text="Learn more" link="https://intranet.bmogc.net/corporate/portal/marketing/design/Pages/default.aspx" buttonclass="footer-button link-button" iconclass="filter-ultramarine" target="_blank" />
              </div>
            }
            <div className="footer-container--logo">
              <div className="footer-container--logo-copyright">
                <p>Copyright {new Date().getFullYear()}. BMO Financial Group</p>
                <img src={bmoLogo} alt="BMO Bank of Montreal Logo" className="footer-logo" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
