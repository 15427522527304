import * as React from "react"
import { Fragment } from "react"
import LinkButton from "./linkbutton"
import bmoLogo from '../images/bmo-roundel-logo-dark-bg.svg'
import { navigate } from "gatsby";


const Layout = ({ location, title, children }) => {
  const [loginStatus, setLoginStatus] = React.useState();

  React.useEffect(() => {
    fetch('https://interactive.bmogc.net/bmo-design-portfolio/auth/')
    // fetch('http://localhost:8888/.netlify/functions/hello-world')
      .then(response => response.text())
      .then(resultData => {
        const networkToken = process.env.GATSBY_NETWORK_TOKEN
        // const networkToken = "Hello world!"
        if (resultData === networkToken) {
          setLoginStatus(true)
        } else {
          console.log("Token incorrect :", networkToken, resultData)
          setLoginStatus(false)
        }
      })
      .catch(err => {
        console.error(err)
        setLoginStatus(false)
      })
  }, []);

  if (loginStatus === false) {
    navigate('404', { state: { hideLinks: true }}, { replace: true });
    return null;
  }
  
  return loginStatus === true ? (
    <Fragment>
      <a href="#main-content" className="header-skip">Skip to main content</a>
      {children}
      <footer>
        <div className="bmo-container">
          <div className="footer-container">
            <div className="footer-container--about-us">
              <h2>We are BMO Design.</h2>
              <p>An award-winning in-house design agency helping our partners achieve marketing goals through the power of great design.</p>
              <LinkButton text="Learn more" link="https://intranet.bmogc.net/corporate/portal/marketing/design/Pages/default.aspx" buttonclass="footer-button link-button" iconclass="filter-ultramarine" target="_blank" />
            </div>
            <div className="footer-container--logo">
              <div className="footer-container--logo-copyright">
                <p>Copyright {new Date().getFullYear()}. BMO Financial Group</p>
                <img src={bmoLogo} alt="BMO Bank of Montreal Logo" className="footer-logo" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  ) : null;
}

export default Layout
