import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Card from "../components/card"

const PortfolioRoll = () => {
  const portfolioLinks = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: {order: DESC, fields: [frontmatter___date]}
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              hero_image {
                src {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: [AUTO, WEBP], placeholder: BLURRED)
                  }
                }
                alt
              }
            }
          }
        }
      }
    `
  )

  const posts = portfolioLinks.allMarkdownRemark.nodes

  return (
    <Fragment>
      <ul className="additional-cards">
        {posts.map((post, index) => {
          const title = post.frontmatter.title

          return (
            <Card cardheader={title} imagedata={post.frontmatter.hero_image.src.childImageSharp.gatsbyImageData} cardbody={post.frontmatter.description} cardtype="small" cardlink={post.fields.slug} key={`card${index}`} imagealt={post.frontmatter.hero_image.alt} />
          )
        })}
      </ul>
    </Fragment>
  )
}

export default PortfolioRoll