import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "../components/card"
import HeaderNav from "../components/header-nav"
import Hero from "../components/hero"

const LandingPage = ({ data, location, title }) => {
  const siteTitle = data.site.siteMetadata?.title || `BMO Design`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <header>
          <HeaderNav />
          <Hero title={siteTitle} />
        </header>
        <p>
          No information found. Add markdown posts to CMS.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} />
      <header>
        <HeaderNav />
        <Hero title={siteTitle} />
      </header>
      <main id="main-content">
        <section className="section--ourworks">
          <div className="bmo-container">
            <h2>Our Work</h2>
            <p className="intro">Explore some of the work we've done.</p>
            <ul className="feature-card">
              <Card cardheader={posts[0].frontmatter.title} imagedata={posts[0].frontmatter.hero_image.src.childImageSharp.gatsbyImageData} cardbody={posts[0].frontmatter.description} cardtype="large" cardlink={posts[0].fields.slug} key="cardtop" imagealt={posts[0].frontmatter.hero_image.alt} />
            </ul>
            <ul className="additional-cards">
              {posts.slice(1).map((post, index) => {
                const title = post.frontmatter.title

                return (
                  <Card cardheader={title} imagedata={post.frontmatter.hero_image.src.childImageSharp.gatsbyImageData} cardbody={post.frontmatter.description} cardtype="small" cardlink={post.fields.slug} key={`card${index}`} imagealt={post.frontmatter.hero_image.alt} />
                )
              })}
            </ul>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          hero_image {
            src {
              childImageSharp {
                gatsbyImageData(quality: 100, formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
            alt
          }
        }
      }
    }
  }
`
