import * as React from "react"
import { Fragment } from "react"
import { graphql } from "gatsby"
import remark from 'remark'
import remarkHTML from 'remark-html'
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeaderNav from "../components/header-nav"
import PortfolioHero from "../components/hero-portfolio"
import PortfolioRoll from "../components/portfolio-roll"
import Lightbox from "../components/lightbox"

const toHTML = value => remark()
                        .use(remarkHTML)
                        .processSync(value)
                        .toString()

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  // use toHTML to parse frontmatter markdown
  const asMarkdownB1 = post.frontmatter.body_one
  const asMarkdownB3 = post.frontmatter.body_three
  const asHTMLB1 = toHTML(asMarkdownB1)
  const asHTMLB3 = toHTML(asMarkdownB3)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <header>
        <HeaderNav />
        <PortfolioHero title={post.frontmatter.title} description={post.frontmatter.description} src={post.frontmatter.hero_image.src.childImageSharp.gatsbyImageData} alt={post.frontmatter.hero_image.alt} />
      </header>
      <main id="main-content">
        <section className="section--portfolio-content">
          <div className="bmo-container">
            <div className="portfolio-content-container">
              <div><h2>Challenge</h2></div>
              <div dangerouslySetInnerHTML={{ __html: asHTMLB1 }}></div>
              <div><h2>Solution</h2></div>
              <div className="solution-content-parent">
                <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                {/* if feature image exists, create wrapper */}
                {
                  post.frontmatter.featureImage &&
                  <div className="feature-image-wrapper">
                    {/* conditional class name to account for margin applied if btn exists */}
                    <GatsbyImage image={post.frontmatter.featureImage.src.childImageSharp.gatsbyImageData} className={`feature-image ${post.frontmatter.galleryImages ? "has-gallery-btn" : ""}`} alt={post.frontmatter.featureImage.alt} />
                    {/* if gallery images exist, import Lightbox carousel */}
                    {
                      post.frontmatter.galleryImages &&
                      <Lightbox galleryImageArray={post.frontmatter.galleryImages} />
                    }
                  </div>
                }
              </div>
              {/* if third body markdown field exists, create row */}
              {
                asMarkdownB3 &&
                <Fragment>
                  <div><h2>Results</h2></div>
                  <div dangerouslySetInnerHTML={{ __html: asHTMLB3 }}></div>
                </Fragment>
              }
            </div>
          </div>
        </section>
        <section className="section--portfolio-moreworks">
          <div className="bmo-container">
            <h2>
              More Work
            </h2>
            <PortfolioRoll />
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        body_one
        body_three
        hero_image {
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, formats: [AUTO, WEBP], placeholder: BLURRED)
            }
          }
          alt
        }
        featureImage {
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, formats: [AUTO, WEBP], placeholder: BLURRED)
            }
          }
          alt
        }
        galleryImages {
          src {
            childImageSharp {
              gatsbyImageData(quality: 100, formats: [AUTO, WEBP], placeholder: BLURRED)
            }
          }
          alt
        }
      }
    }
  }
`
