import * as React from "react"
import { Component, Fragment } from "react"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import { StaticImage } from "gatsby-plugin-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default class Lightbox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showLightbox: false,
    }
  }

  render() {
    const { showLightbox } = this.state
    const galleryImageArray = this.props.galleryImageArray
    console.log(galleryImageArray)

    return (
      <Fragment>
        <button type="button" className="modal-trigger" aria-haspopup="true" modal-trigger="carousel-modal" id="carousel-modal-btn" onClick={() => this.setState({ showLightbox: true })}><StaticImage src="../images/24-external-link.svg" aria-hidden="true" alt="opens popup icon" placeholder="blurred" />View more images</button>
        {
          showLightbox && (
            <Dialog aria-label="Image gallery" onDismiss={() => this.setState({ showLightbox: false })}>
              <button type="button" className="close-modal-btn" onClick={() => this.setState({ showLightbox: false })}><StaticImage src="../images/close-icon.svg" aria-hidden="true" alt="close icon" placeholder="blurred" /><span className="sr-only">close image gallery pop up</span></button>
              <Carousel
                ariaLabel="Image gallery carousel"
                showStatus={false}
                width="100%"
                renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
                  <button type="button" onClick={onClickHandler} title={label} className="carousel-back-btn">
                    <StaticImage src="../images/caret-left.svg" aria-hidden="true" alt="previous slide icon" placeholder="blurred" /><span className="sr-only">previous slide / item</span>
                  </button>
                )}
                renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
                  <button type="button" onClick={onClickHandler} title={label} className="carousel-next-btn">
                    <StaticImage src="../images/caret-right.svg" aria-hidden="true" alt="next slide icon" placeholder="blurred" /><span className="sr-only">next slide / item</span>
                  </button>
                )}
              >
                {
                  galleryImageArray.map((imageInfo, index) => (
                    <img src={imageInfo.src.childImageSharp.gatsbyImageData.images.fallback.src} alt={imageInfo.alt} key={index} />
                  ))
                }
              </Carousel>

            </Dialog>
          )
        }
      </Fragment>
    )
  }
}